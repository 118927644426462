// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
Turbolinks.start()
ActiveStorage.start()

// Jquery
import $ from 'jquery';
global.$ = jQuery;



//Chartjs
import "chartkick/chart.js"

// Bootstrap
import "bootstrap"

// datatables
require('datatables.net-bs5')
require('packs/datatables')


// Stylesheets
require("stylesheets/application.scss")


$(document).on('turbolinks:load', function() {
  // Handle flash-alert delay
  $(".flash-alert").delay(2000).fadeOut(300, function() { $(this).remove(); });

  // Toggle other medical issue input fiels 
  $("#other-form-check").click(function () {
    if ($(this).is(":checked")) {
      $('#other_medical_issues').show();
    } else {
      $('#applicant_other_medical_issues').val('');
      $('#other_medical_issues').hide();
    }
  });

  // Toggle disable applicant_allergy_details input field
  $("#applicant_allergic_true").click(function () {
    if ($(this).is(":checked")) {
      $("#applicant_allergy_details").attr("disabled", false);
    }
  });

  $("#applicant_allergic_false").click(function () {
    if ($(this).is(":checked")) {
      $("#applicant_allergy_details").attr("disabled", true);
      $("#applicant_allergy_details").val('');
    }
  });

  // Toggle disable applicant_vaccine_reaction_details input field
  $("#applicant_vaccine_reaction_true").click(function () {
    if ($(this).is(":checked")) {
      $("#applicant_vaccine_reaction_details").attr("disabled", false);
    }
  });

  $("#applicant_vaccine_reaction_false").click(function () {
    if ($(this).is(":checked")) {
      $("#applicant_vaccine_reaction_details").attr("disabled", true);
      $("#applicant_vaccine_reaction_details").val('');
    }
  });

  $('#selectAll').click(function() {
    if (this.checked) {
      $(':checkbox').each(function() {
        this.checked = true;                        
      });
    } else {
      $(':checkbox').each(function() {
        this.checked = false;                        
      });
    } 
  });
})